<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <section id="dashboard-analytics">
      <b-card :title="setting.homeTitle" v-if="setting.homeTitle">
        <b-card-text></b-card-text>
      </b-card>

      <b-row class="match-height">
        <b-col cols="4" xl="4" lg="4" md="12" sm="12">
          <b-card>
            <b-card-header>
              <b-card-title>LEAD STATISTICS</b-card-title>
            </b-card-header>

            <b-card-body>
              <b-row>
                <b-col sm="2" class="d-flex flex-column flex-wrap text-center">
                  <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
                    {{ leadReportData.total }}
                  </h1>
                  <b-card-text>Total Lead</b-card-text>
                </b-col>

                <!-- chart -->
                <b-col sm="10" class="d-flex justify-content-center">
                  <!-- apex chart -->
                  <vue-apex-charts type="radialBar" height="270" :options="supportTrackerRadialBar.chartOptions" :series="supportTrackerRadialBar.series" />
                </b-col>
                <!--/ chart -->
              </b-row>

              <!-- chart info -->
              <div class="mt-5 d-flex justify-content-between">
                <div class="text-center ml-1">
                  <b-card-text class="mb-50" style="color: red;">
                    Not Completed
                  </b-card-text>
                  <span class="font-large-1 font-weight-bold">{{ leadReportData.notCompleted }}</span>
                </div>
                <div class="text-center mr-1">
                  <b-card-text class="mb-50" style="color: green;">
                    Completed
                  </b-card-text>
                  <span class="font-large-1 font-weight-bold">{{ leadReportData.completed }}</span>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col xl="8" md="8" sm="8" cols="12">
          <b-card v-if="data && $Can('show_inventory_statistic')" no-body class="card-statistics">
            <b-card-header>
              <b-card-title>INVENTORY STATISTICS</b-card-title>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-row>
                <b-col v-for="(item, index) in data.inventoryStatisticsItems" :key="index" xl="3" sm="6" :class="item.customClass">
                  <b-media no-body>
                    <b-media-aside>
                      <b-avatar size="48" :variant="item.color">
                        <feather-icon size="24" :icon="item.icon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ item.title }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ item.subtitle }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col xl="6" md="6" sm="6" cols="12">
          <b-card v-if="data && $Can('show_purchase_statistic')" no-body class="card-statistics">
            <b-card-header>
              <b-card-title>PURCHASE STATISTICS</b-card-title>
              <b-card-text class="mr-25 mb-0">
                {{ dateNow }}
              </b-card-text>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-row>
                <b-col v-for="(item, index) in data.statisticsItems" :key="index" xl="3" sm="6" :class="item.customClass">
                  <b-media no-body>
                    <b-media-aside>
                      <b-avatar size="48" :variant="item.color">
                        <feather-icon size="24" :icon="item.icon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ item.title }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ item.subtitle }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col xl="6" md="6" sm="6" cols="12">
          <b-card v-if="data && $Can('show_sale_statistic')" no-body class="card-statistics">
            <b-card-header>
              <b-card-title>SALE STATISTICS</b-card-title>
              <!-- <b-card-text class="mr-25 mb-0">
                {{ dateNow }}
              </b-card-text> -->
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-row>
                <b-col v-for="(item, index) in data.saleStatisticsItems" :key="index" xl="3" sm="6" :class="item.customClass">
                  <b-media no-body>
                    <b-media-aside>
                      <b-avatar size="48" :variant="item.color">
                        <feather-icon size="24" :icon="item.icon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ item.title }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ item.subtitle }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </b-overlay>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink } from 'bootstrap-vue';
import { kFormatter } from '@core/utils/filter';
import moment from 'moment';
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue';
import homeStoreModule from './homeStoreModule';
import store from '@/store';
import { ref, onUnmounted, reactive } from '@vue/composition-api';

import useJwt from '@/auth/jwt/useJwt';
import router from '@/router';
import VueApexCharts from 'vue-apexcharts';
import { $themeColors } from '@themeConfig';

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,

    StatisticCardWithAreaChart,
    VueApexCharts,
  },

  setup() {
    const HOME_APP_STORE_MODULE_NAME = 'home';
    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
    });
  },

  created() {
    this.getSettings();
  },

  mounted() {
    this.getLeadReport();
  },

  data() {
    const dateNow = moment(new Date()).format('YYYY-MM-DD');

    const data = {
      congratulations: {
        name: 'John',
        saleToday: '48900',
      },
      statisticsItems: [
        {
          icon: 'SaveIcon',
          color: 'light-primary',
          title: '0',
          subtitle: 'Draft',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ClockIcon',
          color: 'light-warning',
          title: '0',
          subtitle: 'Pending Manager',
          customClass: 'mb-2 mb-xl-0',
        },

        {
          icon: 'UserCheckIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Approved',
          customClass: '',
        },

        {
          icon: 'UserXIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'Not Approved',
          customClass: 'mb-2   mb-sm-0',
        },
        {
          icon: 'ChevronsUpIcon',
          color: 'light-info',
          title: '0',
          subtitle: 'Sent To Client',
          customClass: 'mb-2 mt-3 mb-sm-0',
        },
        {
          icon: 'ThumbsUpIcon',
          color: 'success',
          title: '0',
          subtitle: 'Win',
          customClass: 'mt-3',
        },
        {
          icon: 'ThumbsDownIcon',
          color: 'danger',
          title: '0',
          subtitle: 'Lost',
          customClass: 'mt-3',
        },

        {
          icon: 'ThumbsUpIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Win Closed',
          customClass: 'mt-3',
        },
        {
          icon: 'ThumbsDownIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'Cancel Closed',
          customClass: 'mt-3',
        },
        {
          icon: 'ArrowUpIcon',
          color: 'primary',
          title: '0',
          subtitle: 'Buy In',
          customClass: 'mt-3',
        },
        {
          icon: 'ArrowDownLeftIcon',
          color: 'warning',
          title: '0',
          subtitle: 'Trade In',
          customClass: 'mt-3',
        },
        {
          icon: 'ArrowDownRightIcon',
          color: 'info',
          title: '0',
          subtitle: 'Lease Return',
          customClass: 'mt-3',
        },

        {
          icon: 'CheckIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Payment Made',
          customClass: 'mt-3',
        },
        {
          icon: 'MoreHorizontalIcon',
          color: 'light-warning',
          title: '0',
          subtitle: 'Payment Pending',
          customClass: 'mt-3',
        },
      ],
      inventoryStatisticsItems: [
        {
          icon: 'ThumbsUpIcon',
          color: 'success',
          title: '0',
          subtitle: 'Created',
          customClass: 'mt-3',
        },
        {
          icon: 'TruckIcon',
          color: 'light-warning',
          title: '0',
          subtitle: 'Incoming - Consigment',
          customClass: 'mt-3',
        },
        {
          icon: 'ArchiveIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Instock - Consigment',
          customClass: 'mt-3',
        },
        {
          icon: 'MoreHorizontalIcon',
          color: 'light-primary',
          title: '0',
          subtitle: 'Incoming',
          customClass: 'mt-3',
        },
        {
          icon: 'ClockIcon',
          color: 'light-warning',
          title: '0',
          subtitle: 'Instock - Pending',
          customClass: 'mt-3',
        },
        {
          icon: 'ClockIcon',
          color: 'light-primary',
          title: '0',
          subtitle: 'Instock - Pending Receiving',
          customClass: 'mt-3',
        },
        {
          icon: 'ClockIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Instock - Prending Payment',
          customClass: 'mt-3',
        },
        {
          icon: 'ArchiveIcon',
          color: 'warning',
          title: '0',
          subtitle: 'Instock',
          customClass: 'mt-3',
        },
        {
          icon: 'MoreHorizontalIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Sold Pending',
          customClass: 'mt-3',
        },
        {
          icon: 'MoreHorizontalIcon',
          color: 'light-warning',
          title: '0',
          subtitle: 'Delivered - Pending Payment',
          customClass: 'mt-3',
        },
        {
          icon: 'CheckCircleIcon',
          color: 'success',
          title: '0',
          subtitle: 'Sold',
          customClass: 'mt-3',
        },
        {
          icon: 'ShoppingCartIcon',
          color: 'danger',
          title: '0',
          subtitle: 'Delivered',
          customClass: 'mt-3',
        },
        {
          icon: 'ClockIcon',
          color: 'light-secondary',
          title: '0',
          subtitle: 'Closed Pending',
          customClass: 'mt-3',
        },

        {
          icon: 'XCircleIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'Closed',
          customClass: 'mt-3',
        },

        {
          icon: 'DiscIcon',
          color: 'light-warning',
          title: '0',
          subtitle: 'Instock Pending - Sold Order',
          customClass: 'mt-3',
        },
        {
          icon: 'CalendarIcon',
          color: 'light-warning',
          title: '0',
          subtitle: 'Incoming - Sold Order',
          customClass: 'mt-3',
        },
        {
          icon: 'HexagonIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Consigment - Sold Order',
          customClass: 'mt-3',
        },
        {
          icon: 'InfoIcon',
          color: 'success',
          title: '0',
          subtitle: 'Sold Order',
          customClass: 'mt-3',
        },
        {
          icon: 'MinusIcon',
          color: 'light-warning',
          title: '0',
          subtitle: 'Funded - Pending Delivery',
          customClass: 'mt-3',
        },
        {
          icon: 'OctagonIcon',
          color: 'light-primary',
          title: '0',
          subtitle: 'Instock Pending - Sold Order',
          customClass: 'mt-3',
        },
      ],
      saleStatisticsItems: [
        {
          icon: 'SaveIcon',
          color: 'light-primary',
          title: '0',
          subtitle: 'Draft',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ClockIcon',
          color: 'light-warning',
          title: '0',
          subtitle: 'Pending Manager',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserCheckIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Approved',
          customClass: '',
        },

        {
          icon: 'UserXIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'Not Approved',
          customClass: 'mb-2   mb-sm-0',
        },
        {
          icon: 'ChevronsUpIcon',
          color: 'light-info',
          title: '0',
          subtitle: 'Sent To Client',
          customClass: 'mb-2 mt-3 mb-sm-0',
        },
        {
          icon: 'ThumbsUpIcon',
          color: 'success',
          title: '0',
          subtitle: 'Win',
          customClass: 'mt-3',
        },
        {
          icon: 'ThumbsDownIcon',
          color: 'danger',
          title: '0',
          subtitle: 'Lost',
          customClass: 'mt-3',
        },

        {
          icon: 'ThumbsUpIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Win Closed',
          customClass: 'mt-3',
        },
        {
          icon: 'ThumbsDownIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'Canceled',
          customClass: 'mt-3',
        },
        {
          icon: 'CheckIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Payment Received',
          customClass: 'mt-3',
        },
        {
          icon: 'MoreHorizontalIcon',
          color: 'light-warning',
          title: '0',
          subtitle: 'Payment Pending',
          customClass: 'mt-3',
        },
      ],
      statisticsOrder: {
        series: [
          {
            name: '2020',
            data: [45, 85, 65, 45, 65],
          },
        ],
      },
      statisticsProfit: {
        series: [
          {
            data: [0, 20, 5, 30, 15, 45],
          },
        ],
      },
      earningsChart: {
        series: [53, 16, 31],
      },
      revenue: {
        years: ['2020', '2019', '2018'],
        price: '25,852',
        budget: '56,800',
        revenueReport: {
          series: [
            {
              name: 'Earning',
              data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
            },
            {
              name: 'Expense',
              data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
            },
          ],
        },
        budgetChart: {
          series: [
            {
              data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
            },
            {
              data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
            },
          ],
        },
      },
      companyTable: [
        {
          avatarImg: require('@/assets/images/icons/toolbox.svg'),
          title: 'Dixons',
          subtitle: 'meguc@ruj.io',
          avatarIcon: 'MonitorIcon',
          avatarColor: 'light-primary',
          avatarTitle: 'Technology',
          viewTitle: '23.4k',
          viewsub: 'in 24 hours',
          revenue: '891.2',
          sales: '68',
          loss: true,
        },
        {
          avatarImg: require('@/assets/images/icons/parachute.svg'),
          title: 'Motels',
          subtitle: 'vecav@hodzi.co.uk',
          avatarIcon: 'CoffeeIcon',
          avatarColor: 'light-success',
          avatarTitle: 'Grocery',
          viewTitle: '78k',
          viewsub: 'in 2 days',
          revenue: '668.51',
          sales: '97',
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/brush.svg'),
          title: 'Zipcar',
          subtitle: 'davcilse@is.gov',
          avatarIcon: 'WatchIcon',
          avatarColor: 'light-warning',
          avatarTitle: 'Fashion',
          viewTitle: '162',
          viewsub: 'in 5 days',
          revenue: '522.29',
          sales: '62',
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/star.svg'),
          title: 'Owning',
          subtitle: 'us@cuhil.gov',
          avatarIcon: 'MonitorIcon',
          avatarColor: 'light-primary',
          avatarTitle: 'Technology',
          viewTitle: '214',
          viewsub: 'in 24 hours',
          revenue: '291.01',
          sales: '88',
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/book.svg'),
          title: 'Cafés',
          subtitle: 'pudais@jife.com',
          avatarIcon: 'CoffeeIcon',
          avatarColor: 'light-success',
          avatarTitle: 'Grocery',
          viewTitle: '208',
          viewsub: 'in 1 week',
          revenue: '783.93',
          sales: '16',
          loss: true,
        },
        {
          avatarImg: require('@/assets/images/icons/rocket.svg'),
          title: 'Kmart',
          subtitle: 'bipri@cawiw.com',
          avatarIcon: 'WatchIcon',
          avatarColor: 'light-warning',
          avatarTitle: 'Fashion',
          viewTitle: '990',
          viewsub: 'in 1 month',
          revenue: '780.05',
          sales: '78',
          loss: false,
        },
        {
          avatarImg: require('@/assets/images/icons/speaker.svg'),
          title: 'Payers',
          subtitle: 'luk@izug.io',
          avatarIcon: 'WatchIcon',
          avatarColor: 'light-warning',
          avatarTitle: 'Fashion',
          viewTitle: '12.9k',
          viewsub: 'in 12 hours',
          revenue: '531.49',
          sales: '42',
          loss: false,
        },
      ],
      meetup: {
        mediaData: [
          {
            avatar: 'CalendarIcon',
            title: 'Sat, May 25, 2020',
            subtitle: '10:AM to 6:PM',
          },
          {
            avatar: 'MapPinIcon',
            title: 'Central Park',
            subtitle: 'Manhattan, New york City',
          },
        ],
        avatars: [
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
            fullName: 'Billy Hopkins',
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
            fullName: 'Amy Carson',
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
            fullName: 'Brandon Miles',
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
            fullName: 'Daisy Weber',
          },
          {
            avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
            fullName: 'Jenny Looper',
          },
        ],
      },
      goalOverview: {
        completed: '786,617',
        inProgress: '13,561',
        series: [83],
      },
      transactionData: [
        {
          mode: 'Wallet',
          types: 'Starbucks',
          avatar: 'PocketIcon',
          avatarVariant: 'light-primary',
          payment: '-$74',
          deduction: true,
        },
        {
          mode: 'Bank Transfer',
          types: 'Add Money',
          avatar: 'CheckIcon',
          avatarVariant: 'light-success',
          payment: '+$480',
          deduction: false,
        },
        {
          mode: 'Paypal',
          types: 'Add Money',
          avatar: 'DollarSignIcon',
          avatarVariant: 'light-danger',
          payment: '+$480',
          deduction: false,
        },
        {
          mode: 'Mastercard',
          types: 'Ordered Food',
          avatar: 'CreditCardIcon',
          avatarVariant: 'light-warning',
          payment: '-$23',
          deduction: true,
        },
        {
          mode: 'Transfer',
          types: 'Refund',
          avatar: 'TrendingUpIcon',
          avatarVariant: 'light-info',
          payment: '+$98',
          deduction: false,
        },
      ],
    };
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      data,
      dateNow,
      setting: {
        homeTitle: null,
        logo: null,
        title: null,
      },
      formShow: false,
      leadReportData: {
        total: 0,
        notCompleted: 0,
        completed: 0,
        percentage: 0,
      },
      supportTrackerRadialBar: {
        series: [],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Completed Leads'],
        },
      },
    };
  },

  methods: {
    kFormatter,

    getLeadReport() {
      store
        .dispatch('home/fetchLeadsReport')
        .then((res) => {
          this.leadReportData.total = res.data.total;
          this.leadReportData.completed = res.data.completed;
          this.leadReportData.notCompleted = res.data.notCompleted;

          //calculate complete percentage
          this.supportTrackerRadialBar.series = [];

          this.leadReportData.percentage = ((this.leadReportData.completed / this.leadReportData.total) * 100).toFixed(2);
          this.supportTrackerRadialBar.series.push(this.leadReportData.percentage);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    getSettings() {
      this.formShow = true;
      this.userId = parseInt(this.userData.id);

      store
        .dispatch('home/fetchSettings', { id: this.userId })
        .then((response) => {
          if (response.data.user.status != 'A') {
            // Remove userData from localStorage

            // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);

            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

            // Remove userData from localStorage
            localStorage.removeItem('userData');

            // Redirect to login page
            router.push({ name: 'auth-login' });
          } else {
            this.setting = {
              homeTitle: response.data.settings.homeTitle,
              logo: response.data.settings.logo,
              title: response.data.settings.title,
            };

            this.data.saleStatisticsItems[0].title = response.data.saleQuoteInformation.valueA;
            this.data.saleStatisticsItems[1].title = response.data.saleQuoteInformation.valueB;
            this.data.saleStatisticsItems[2].title = response.data.saleQuoteInformation.valueC;
            this.data.saleStatisticsItems[3].title = response.data.saleQuoteInformation.valueD;
            this.data.saleStatisticsItems[4].title = response.data.saleQuoteInformation.valueE;
            this.data.saleStatisticsItems[5].title = response.data.saleQuoteInformation.valueF;
            this.data.saleStatisticsItems[6].title = response.data.saleQuoteInformation.valueP;
            this.data.saleStatisticsItems[7].title = response.data.saleQuoteInformation.valueH;
            this.data.saleStatisticsItems[8].title = response.data.saleQuoteInformation.valueJ;
            this.data.saleStatisticsItems[9].title = response.data.saleQuoteInformation.valueYes;
            this.data.saleStatisticsItems[10].title = response.data.saleQuoteInformation.valueNo;

            this.data.statisticsItems[0].title = response.data.purchaseQuoteInformation.valueA;
            this.data.statisticsItems[1].title = response.data.purchaseQuoteInformation.valueB;
            this.data.statisticsItems[2].title = response.data.purchaseQuoteInformation.valueC;
            this.data.statisticsItems[3].title = response.data.purchaseQuoteInformation.valueD;
            this.data.statisticsItems[4].title = response.data.purchaseQuoteInformation.valueE;
            this.data.statisticsItems[5].title = response.data.purchaseQuoteInformation.valueF;
            this.data.statisticsItems[6].title = response.data.purchaseQuoteInformation.valueP;
            this.data.statisticsItems[7].title = response.data.purchaseQuoteInformation.valueH;
            this.data.statisticsItems[8].title = response.data.purchaseQuoteInformation.valueJ;
            this.data.statisticsItems[9].title = response.data.purchaseQuoteInformation.value1;
            this.data.statisticsItems[10].title = response.data.purchaseQuoteInformation.value2;
            this.data.statisticsItems[11].title = response.data.purchaseQuoteInformation.value3;
            this.data.statisticsItems[12].title = response.data.purchaseQuoteInformation.valueYes;
            this.data.statisticsItems[13].title = response.data.purchaseQuoteInformation.valueNo;

            this.data.inventoryStatisticsItems[0].title = response.data.inventoryQuoteInformation.valueA + response.data.inventoryQuoteInformation.valueP;
            this.data.inventoryStatisticsItems[1].title = response.data.inventoryQuoteInformation.valueB;
            this.data.inventoryStatisticsItems[2].title = response.data.inventoryQuoteInformation.valueC;
            this.data.inventoryStatisticsItems[3].title = response.data.inventoryQuoteInformation.valueD;
            this.data.inventoryStatisticsItems[4].title = response.data.inventoryQuoteInformation.valueI;
            this.data.inventoryStatisticsItems[5].title = response.data.inventoryQuoteInformation.valueE;
            this.data.inventoryStatisticsItems[6].title = response.data.inventoryQuoteInformation.valueF;
            // this.data.inventoryStatisticsItems[7].title = response.data.inventoryQuoteInformation.valueP;
            this.data.inventoryStatisticsItems[7].title = response.data.inventoryQuoteInformation.valueG;
            this.data.inventoryStatisticsItems[8].title = response.data.inventoryQuoteInformation.valuePA;
            this.data.inventoryStatisticsItems[9].title = response.data.inventoryQuoteInformation.valuePB;
            this.data.inventoryStatisticsItems[10].title = response.data.inventoryQuoteInformation.valuePC;
            this.data.inventoryStatisticsItems[11].title = response.data.inventoryQuoteInformation.valuePD;
            this.data.inventoryStatisticsItems[12].title = response.data.inventoryQuoteInformation.valuePE;
            this.data.inventoryStatisticsItems[13].title = response.data.inventoryQuoteInformation.valuePEC;
            this.data.inventoryStatisticsItems[14].title = response.data.inventoryQuoteInformation.valuePF;
            this.data.inventoryStatisticsItems[15].title = response.data.inventoryQuoteInformation.valuePG;
            this.data.inventoryStatisticsItems[16].title = response.data.inventoryQuoteInformation.valuePH;
            this.data.inventoryStatisticsItems[17].title = response.data.inventoryQuoteInformation.valuePI;
            this.data.inventoryStatisticsItems[18].title = response.data.inventoryQuoteInformation.valuePK;
            this.data.inventoryStatisticsItems[19].title = response.data.inventoryQuoteInformation.valuePL;

            this.formShow = false;
          }
        })
        .catch((error) => {
          this.formShow = false;
          this.$swal({
            title: 'Error!',
            text: 'Oops, an error has occurred',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
